'use client';

import { sessionStorageHelper } from '@checkout/Utils/sessionStorageHelper';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const finCalcInteractedWithAtom = atom<boolean>(false);

export const finCalcInteractedWithStorageAtom = atomWithStorage<boolean>(
  'finCalcInteractedWith',
  false,
  sessionStorageHelper as any,
);
