'use client';

import React from 'react';
import cn from 'classnames';

import getGridClassNames from '../Utils/GetGridClassNames';

import { ColProps } from './Col.types';

import guttersStyles from '../Styles/Gutters.module.css';
import styles from './Col.module.css';

export function Col<E extends React.ElementType = 'div'>({
  component,
  className,
  align,
  order,
  offset,
  width,
  children,
  dataTestId,
  ...props
}: ColProps<E>) {
  const Component = component ?? 'div';

  const gridClassNames = getGridClassNames({ order, offset, width }, styles);

  const classNames = cn(
    guttersStyles.col,
    styles.component,
    align && styles[`align-${align}`],
    ...gridClassNames,
    className,
  );

  return (
    <Component className={classNames} data-test-id={dataTestId} {...props}>
      {children}
    </Component>
  );
}

Col.displayName = 'Col';
