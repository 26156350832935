/* eslint-disable @typescript-eslint/naming-convention */
import { Col } from './Components/Col';
import { Container } from './Components/Container';
import { Row } from './Components/Row';

export { Col } from './Components/Col';
export { Container } from './Components/Container';
export { Row } from './Components/Row';

export const Grid = {
  Row,
  Col,
  Container,
  displayName: 'Grid',
};

export default Grid;
