'use client';

import React, {
  FC,
  ChangeEvent,
  FocusEvent,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import cn from 'classnames';

import { FormControl } from '../FormControl/FormControl';

import TextareaCounter from './Components/TextareaCounter';
import { TextareaProps } from './Textarea.types';

import styles from './Styles/Textarea.module.css';

export const Textarea: FC<TextareaProps> = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      value,
      defaultValue,
      hint,
      error,
      label,
      maxLength,
      fullWidth = false,
      resize = 'none',
      disabled,
      readOnly,
      onFocus,
      onBlur,
      onChange,
      className,
      dataTestId,
      autoSize = false,
      hideCounterMobile = false,
      oneLiner = false,
      isExperimentEnabled = false,
      ...restProps
    },
    ref,
  ) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [_, setTextareaHeight] = useState<number | string>('auto');
    const textareaClassNames = cn('typography-body-3', styles.textarea, className, {
      [styles.resizeVertical]: resize === 'vertical',
    });
    const isUncontrolled = value === undefined;
    const ariaLabel = typeof label === 'string' ? label : undefined;
    const [isFocused, setFocused] = useState(restProps.autoFocus);
    const [stateValue, setStateValue] = useState(defaultValue || '');
    const isFilled = Boolean(isUncontrolled ? stateValue : value);

    const setAutoSize = () => {
      if (autoSize && textareaRef.current && !isExperimentEnabled) {
        textareaRef.current.style.height = 'auto';
        const newHeight = `${textareaRef.current.scrollHeight}px`;
        setTextareaHeight(newHeight);
        textareaRef.current.style.height = newHeight;
      }
    };

    useEffect(() => {
      setAutoSize();
    }, [value]);

    const handleTextareaFocus = useCallback(
      (event: FocusEvent<HTMLTextAreaElement>) => {
        if (!readOnly) setFocused(true);
        if (onFocus) onFocus(event);
      },
      [onFocus, readOnly],
    );
    const handleTextareaBlur = useCallback(
      (event: FocusEvent<HTMLTextAreaElement>) => {
        setFocused(false);

        if (onBlur) onBlur(event);
      },
      [onBlur],
    );
    const handleTextareaChange = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        if (onChange) onChange(event, { value: event.target.value });
        if (isUncontrolled) setStateValue(event.target.value);

        setAutoSize();
      },
      [onChange, isUncontrolled],
    );
    const getValueLength = (): number => {
      if (isUncontrolled) return stateValue.length;

      return value.length;
    };

    return (
      <FormControl
        bottomAddons={
          !!maxLength && (
            <TextareaCounter
              length={getValueLength()}
              maxLength={maxLength}
              hideMobile={hideCounterMobile}
            />
          )
        }
        className={styles.formControl}
        disabled={disabled}
        error={error}
        filled={isFilled || isFocused}
        focused={isFocused}
        fullWidth={fullWidth}
        hint={hint}
        label={label}
      >
        <textarea
          {...restProps}
          aria-label={ariaLabel}
          className={textareaClassNames}
          data-test-id={dataTestId}
          disabled={disabled}
          maxLength={maxLength}
          onBlur={handleTextareaBlur}
          onChange={handleTextareaChange}
          onFocus={handleTextareaFocus}
          readOnly={readOnly}
          ref={textareaElement => {
            if (ref) {
              if (typeof ref === 'function') {
                ref(textareaElement);
              } else if (typeof ref === 'object' && ref !== null) {
                ref.current = textareaElement;
              }
            }
            textareaRef.current = textareaElement;
          }}
          value={value}
          rows={oneLiner || isExperimentEnabled ? 1 : 3}
        />
      </FormControl>
    );
  },
);
