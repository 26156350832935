/**
 * Formats a given Date to "yyyy-mm-dd"
 */
export function formatDateToYMD(date: Date): string {
  const year = date.getFullYear();
  const month = padToTwoDigits(date.getMonth() + 1); // Months are zero-based
  const day = padToTwoDigits(date.getDate());

  return `${year}-${month}-${day}`;
}

/**
 * Formats a given Date to "yyyy-mm-dd hh:mm"
 */
export function formatDateToYMDHM(date: Date): string {
  const year = date.getFullYear();
  const month = padToTwoDigits(date.getMonth() + 1);
  const day = padToTwoDigits(date.getDate());
  const hours = padToTwoDigits(date.getHours());
  const minutes = padToTwoDigits(date.getMinutes());

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

function padToTwoDigits(number: number): string {
  return number < 10 ? '0' + number : number.toString();
}
