'use client';

import React from 'react';
import cn from 'classnames';

import getGridClassNames from '../Utils/GetGridClassNames';

import { RowProps } from './Row.types';

import guttersStyles from '../Styles/Gutters.module.css';
import styles from './Row.module.css';

export function Row<E extends React.ElementType = 'div'>({
  component,
  className,
  gutter = {
    mobile: 8,
    tablet: { s: 12, l: 16 },
    desktop: {
      s: 16,
      m: 16,
      l: 24,
    },
  },
  align,
  justify = 'between',
  children,
  dataTestId,
  ...props
}: RowProps<E>) {
  const Component = component ?? 'div';

  const gridClassNames = getGridClassNames({ gutter }, guttersStyles);

  const classNames = cn(
    guttersStyles.row,
    styles.component,
    align && styles[align],
    styles[justify],
    ...gridClassNames,
    className,
  );

  return (
    <Component className={classNames} data-test-id={dataTestId} {...props}>
      {children}
    </Component>
  );
}

Row.displayName = 'Row';
