import Cookies from 'js-cookie';
import { LeadPayload, Payload } from './BuildLeadPayload.entities';
import { addMinutesToDate } from '../Date/AddMinutesToDate';
import { formatDateToYMD, formatDateToYMDHM } from '../Date/FormatDate';
import { utcToZonedTime, nowAsUTCDate } from '../Date/ToTimeZonedDate';
import { LocaleTimezone } from '../Date/LocaleTimezone';

export const getSource = (tenant: string | undefined, source: string): string => {
  const activeServiceConsumer = Cookies.get('activeServiceConsumer');
  const isNative = activeServiceConsumer === 'native';

  if (isNative) {
    return 'native';
  } else {
    return tenant ?? source;
  }
};

export function buildLeadPayload(props: LeadPayload) {
  const {
    vehicle,
    values,
    subtype,
    source,
    tenant,
    extraData,
    valuation,
    similarVehicles,
    calculation,
    financeQuoteShowing,
    leadType,
    leadId,
    isFinCalcInteractedWith,
    kasiaCallback,
    eligibleProduct,
  } = props;

  const isIndividualChkbxDriftrockLead = !!values.driftrockChkBox;
  const isDriftrockLead = !!values.oneClickDriftrockChkBox || isIndividualChkbxDriftrockLead;

  const selectedLeadType =
    leadId === 'leasing-online'
      ? 'lease_a_car'
      : leadId === 'request-callback' && kasiaCallback
      ? 'callback'
      : leadId === 'message-dealer' && values.callBackInstantlyChkBox
      ? 'callback'
      : leadType || 'message';
  const pathname = window.location.pathname;
  const isLeasingUrl = pathname.endsWith('/leasing');

  const dealerTimeNow = vehicle
    ? utcToZonedTime(nowAsUTCDate(), LocaleTimezone[vehicle.locale])
    : null;

  const payload: Payload = {
    lead_type: selectedLeadType,
    subtype,
    status: 'active',
    source: getSource(tenant, source),
    is_test: false,
    final: false,
    attribution: null,
    items:
      leadId === 'leasing-online' && isLeasingUrl
        ? []
        : vehicle
        ? [
            {
              vehicle: {
                primary_id: vehicle?.heycarId,
                ...(similarVehicles?.length &&
                  (values.oneClickChkBox || values.oneClickDriftrockChkBox) && {
                    additional_ids: similarVehicles
                      .map(vehicle => vehicle.heycarId ?? vehicle.id)
                      .filter(Boolean)
                      .slice(0, 5),
                  }),
              },
            },
          ]
        : [],
    customer: {
      title: values.title?.value,
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: values.phone,
      address: { postcode: values.postcode },
      optin_preferences: {
        email_optin: isDriftrockLead ? false : !!values.marketingOptIns || !!values.emailOptIn,
        phone_optin: isDriftrockLead ? false : !!values.marketingOptIns,
        sms_optin: isDriftrockLead ? false : !!values.marketingOptIns,
        optin_source: `${subtype}_submit`,
        multi_lead_optin: isDriftrockLead,
      },
    },
    arbitrary_data: {
      message: values.message,
      referring_page: window.location.pathname + window.location.search,
      partx_mileage: values.valuationChkBox
        ? valuation?.vehicle.mileage
        : values.mileage || undefined,
      partx_registration: values.valuationChkBox
        ? valuation?.vehicle.registration
        : values.registration || undefined,
      date: values.date ? values.date : values.date,
      preferred_time: values.preferredTime?.value,
      callback_datetime:
        values.kaisaTime && values.date + ' ' + (values.kaisaTime?.value?.substring(0, 5) ?? ''),
      ...(values.leadId && { update_lead: values.leadId }),
      ...(values.rpiResponse !== undefined && { rpi_response: values.rpiResponse }),
      ...(values.callBackInstantlyChkBox &&
        dealerTimeNow && {
          callback_type: 'instant',
          callback_datetime: formatDateToYMDHM(addMinutesToDate(dealerTimeNow, 2)),
          date: formatDateToYMD(dealerTimeNow),
        }),
    },
    ...extraData,
  };

  if (isIndividualChkbxDriftrockLead && eligibleProduct) {
    payload.arbitrary_data.multi_lead_info = eligibleProduct;
  }

  if (values.financeQuoteChkBox && financeQuoteShowing && calculation) {
    payload.arbitrary_data.finance = {
      has_interacted_with_calculator: isFinCalcInteractedWith,
      quote: {
        cash_deposit:
          calculation?.products?.[calculation.selectedProduct]?.calculationResult?.cashDeposit,
        final_payment:
          calculation?.products?.[calculation.selectedProduct]?.calculationResult
            ?.optionalFinalPayment,
        monthly_payments: [
          {
            amount:
              calculation?.products?.[calculation.selectedProduct]?.calculationResult
                ?.totalMonthlyRate,
            number_of_payments:
              calculation?.products?.[calculation.selectedProduct]?.inputs?.months?.value,
          },
        ],
        apr: calculation?.products?.[calculation.selectedProduct]?.calculationResult
          ?.annualInterestRate,
        charges_for_credit:
          calculation?.products?.[calculation.selectedProduct]?.calculationResult?.chargesForCredit,
        annual_mileage:
          calculation?.products?.[calculation.selectedProduct]?.inputs?.mileage?.value,
        balance: calculation?.products?.[calculation.selectedProduct]?.calculationResult?.balance,
        contract_length:
          calculation?.products?.[calculation.selectedProduct]?.inputs?.months?.value,
        lender: calculation?.lender,
        excess_mileage_rate:
          calculation?.products?.[calculation.selectedProduct]?.calculationResult
            ?.excessMileageRate,
        type: calculation?.selectedProduct,
        total_amount_payable:
          calculation?.products?.[calculation.selectedProduct]?.calculationResult?.totalAmount,
        total_price:
          calculation?.products?.[calculation.selectedProduct]?.calculationResult?.totalPrice,
        key: calculation?.selectedProduct,
        finance_type: calculation?.selectedProduct.toLowerCase(),
        deposit_contribution:
          calculation?.products?.[calculation.selectedProduct]?.calculationResult
            ?.totalContribution,
        total_deposit:
          calculation?.products?.[calculation.selectedProduct]?.calculationResult?.totalDeposit,
        regular_payment:
          calculation?.products?.[calculation.selectedProduct]?.calculationResult?.totalMonthlyRate,
        term: calculation?.products?.[calculation.selectedProduct]?.inputs?.months?.value,
        customer_deposit:
          calculation?.products?.[calculation.selectedProduct]?.calculationResult?.cashDeposit,
        rate_of_interest:
          calculation?.products?.[calculation.selectedProduct]?.calculationResult
            ?.annualInterestRate,

        // Additional FR fields
        provider: calculation?.captiveId,
        down_payment: calculation?.products?.[calculation.selectedProduct]?.inputs?.deposit?.value,
        monthly_installment:
          calculation?.products?.[calculation.selectedProduct]?.calculationResult?.totalMonthlyRate,
        duration: calculation?.products?.[calculation.selectedProduct]?.inputs?.months?.value,
        opening_fee:
          calculation?.products?.[calculation.selectedProduct]?.calculationResult
            ?.applicationCostFee,
        tin: calculation?.products?.[calculation.selectedProduct]?.calculationResult
          ?.nominalInterestRate,
        tae: calculation?.products?.[calculation.selectedProduct]?.calculationResult
          ?.annualInterestRate,
        total_amount:
          calculation?.products?.[calculation.selectedProduct]?.calculationResult?.totalAmount,
        total_amount_financed:
          calculation?.products?.[calculation.selectedProduct]?.calculationResult?.netLoanAmount,
        contract_value:
          calculation?.products?.[calculation.selectedProduct]?.calculationResult?.residualPrice,
        first_installment:
          calculation?.products?.[calculation.selectedProduct]?.calculationResult?.firstInstallment,
        additional_service:
          calculation?.products?.[calculation.selectedProduct]?.additionalServices,
        selectedProduct: calculation?.selectedProduct,
        contract_total_mileage:
          calculation?.products?.[calculation.selectedProduct]?.calculationResult
            ?.contractTotalMileage,
        offer_package_name: calculation?.products?.[calculation.selectedProduct]?.offerPackageName,
      },
    };
  }
  return payload;
}
