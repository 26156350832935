export const kontentLoader = ({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality?: number;
}) => {
  // Split the original Kontent URL to extract the asset ID and filename
  const urlParts = src.split('/');
  const projectId = urlParts[urlParts.length - 3];
  const assetId = urlParts[urlParts.length - 2];
  const filename = urlParts[urlParts.length - 1];

  // Construct the URL for the transformed image
  const transformedUrl = `https://preview-assets-eu-01.kc-usercontent.com:443/${projectId}/${assetId}/${filename}?w=${width}&q=${
    quality || 80
  }&fm=webp&lossless=0`;

  return transformedUrl;
};
