'use client';

import { Typography } from '@gds/Typography/Typography';

import styles from './ReserveNowBadge.module.css';

interface ReserveNowBadgeProps {
  desktopString: string;
  mobileString?: string;
}

export const ReserveNowBadge = ({ desktopString, mobileString }: ReserveNowBadgeProps) => {
  return (
    <div className={styles.badgeWrapper}>
      <div className={`${styles.badge} ${mobileString && styles.hasMobileString}`}>
        <Typography variant="subheading3">{desktopString}</Typography>
        {mobileString && <Typography variant="subheading3">{mobileString}</Typography>}
      </div>
    </div>
  );
};
