/**
 * Returns the current date time as a UTC date.
 */
export const nowAsUTCDate = () => {
  const now = new Date();
  return asUTCDate(now);
};

/**
 * Converts a given date to a UTC date.
 */
export const asUTCDate = (date: Date) => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60_000);
};

/**
 * Converts a given UTC date to a date in the specified timezone.
 */
export const utcToZonedTime = (utcDate: Date, timezone: string) => {
  const timezoneOffset = getTimezoneOffset(utcDate, timezone);

  return new Date(utcDate.getTime() - timezoneOffset);
};

/**
 * Accepts the date as a string and the _timezone of that date_.
 * Returns the date as a Date object (local timezone by default)
 */
export const parseWithTimezone = (dateAsString: string, timezone: string) => {
  const currentTimezoneOffset = new Date().getTimezoneOffset() * 60_000;

  const timezoneOffset = getTimezoneOffset(new Date(), timezone);

  const date = new Date(dateAsString);

  const dateWithOffset = new Date(date.getTime() + timezoneOffset);

  const dateWithCurrentOffset = new Date(dateWithOffset.getTime() - currentTimezoneOffset);

  return dateWithCurrentOffset;
};

const getTimezoneOffset = (utcDate: Date, timezone: string): number => {
  const tzFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
    timeZone: timezone,
  });

  const parts = tzFormatter.formatToParts(utcDate);
  const data: { [key: string]: string } = {};
  parts.forEach(({ type, value }) => {
    if (type !== 'literal') {
      data[type] = value;
    }
  });
  if (data.dayPeriod === 'PM') {
    data.hour = String(Number(data.hour) + 12);
    if (data.hour === '24') {
      data.hour = '0';
    }
  }

  const tzDate = new Date(
    Date.UTC(
      parseInt(data.year),
      parseInt(data.month) - 1,
      parseInt(data.day),
      parseInt(data.hour),
      parseInt(data.minute),
      parseInt(data.second),
    ),
  );

  return utcDate.getTime() - tzDate.getTime();
};
