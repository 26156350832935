'use client';

import { Car } from '@gds/Icons/Paths/Car';
import { Collect } from '@gds/Icons/Paths/Collect';
import { MBG } from '@gds/Icons/Paths/MBG';

import { Typography } from '@gds/Typography/Typography';

import styles from './ReserveNowHowItWorks.module.css';

interface ReserveNowHowItWorksProps {
  strings: Record<string, any>;
}

export const ReserveNowHowItWorks = ({ strings }: ReserveNowHowItWorksProps) => {
  return (
    <div className={styles.howItWorksWrapper}>
      <div className={styles.howItWorks}>
        <div className={styles.iconWrapper}>
          <MBG fontSize={24} />
        </div>
        <Typography variant="subheading3" Component="div">
          {strings.confirmReservationHold}
        </Typography>
      </div>
      <div className={styles.howItWorks}>
        <div className={styles.iconWrapper}>
          <Car fontSize={24} />
        </div>
        <Typography variant="subheading3" Component="div">
          {strings.carIsReserved}
        </Typography>
      </div>
      <div className={styles.howItWorks}>
        <div className={styles.iconWrapper}>
          <Collect fontSize={24} />
        </div>
        <Typography variant="subheading3" Component="div">
          {strings.chooseToBuyOrCancel}
        </Typography>
      </div>
    </div>
  );
};
