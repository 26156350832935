import { Vehicle } from '@core/Entities/Vehicle/Vehicle.entity';

import { VehicleListingFeatureSubset } from './ExtractVehicleListingFeatureSubset';
import { ListingFeatureEvent } from './ListingFeatureEvent';
import { listingFeatureSchema } from './ListingFeatureSchema';

const getActiveOffer = (offers: string[] | undefined): string | undefined => {
  if (Array.isArray(offers)) {
    const priorityOffers = ['heycar_days_112024']; // more campaigns/marker Id can be added here
    return offers.find(offer => priorityOffers.some(priority => offer.includes(priority)));
  }
};

const determineEcomType = (vehicle: Vehicle | VehicleListingFeatureSubset): string | undefined => {
  if (vehicle?.eligibleProducts?.includes('HEYCAR_CONNECT')) {
    return 'connect';
  }
  if (vehicle?.eligibleProducts?.includes('ONLY_ON_HEYCAR')) {
    return 'investor';
  }
  return undefined;
};

export const vehicleToListingFeatureEvent = (
  vehicle: Vehicle | VehicleListingFeatureSubset,
  extraData?: Record<string, any>,
): ListingFeatureEvent => {
  const event: ListingFeatureEvent = {
    schema: listingFeatureSchema.ref,
    data: {
      active_offer: getActiveOffer(vehicle?.offers?.offers),
      buy_online: vehicle?.ecom?.isEcom!,
      featured_listing: vehicle?.details?.isFeatured,
      has_finance: vehicle?.pricing?.hasFinance,
      has_hp: !!vehicle?.monthlyPrice?.has_hp, // Assuming monthlyPrice exists and has the properties.
      has_pcp: !!vehicle?.monthlyPrice?.has_pcp, // Same assumption as above.
      holding_image: vehicle?.num_images > 0, // True if there's at least one image.
      is_manufacturer_approved: vehicle?.details?.isFactoryApproved,
      last_updated: vehicle?.details?.lastUpdatedAt ?? vehicle?.details?.createdAt!,
      newly_listed: vehicle?.details?.newlyListed,
      num_images: vehicle?.num_images || 0,
      price_rating: vehicle?.pricing?.priceRating!, // Assuming this exists in the vehicle object.
      price_reduced: vehicle?.pricing?.isReducedPrice,
      promotions: vehicle?.offers?.promotions, // Assuming promotions is an array of strings.
      discount_applied: vehicle?.pricing?.discount,
      has_oneClick_chkBox: extraData?.has_oneClick_chkBox,
      is_oneclick_chkBox_checked: extraData?.is_oneclick_chkBox_checked,
      num_similarVehicles: extraData?.num_similarVehicles,
      has_driftrock_chkBox: extraData?.has_driftrock_chkBox,
      is_driftrock_chkBox_checked: extraData?.is_driftrock_chkBox_checked,
      vehicle_guarantee: extraData?.guaranteeName,
      has_review: extraData?.hasReview,
      ecom_type: determineEcomType(vehicle),
    },
  };

  return event;
};
