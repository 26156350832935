'use client';

import { useEffect } from 'react';

import { loadGoogleRecaptcha } from '@core/Utils/loadGoogleRecaptcha';
import { Locale } from '@core/Entities/Locale/Locale.entity';

export const useGoogleRecaptcha = (locale: Locale) => {
  useEffect(() => {
    const loadScript = async () => {
      try {
        await loadGoogleRecaptcha(locale);
      } catch (error) {
        console.error(error);
      }
    };
    loadScript();
  }, []);
};
